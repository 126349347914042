<template>
  <div>
    <div class="container">
        <!-- <div class="alert alert-warning">
          EasyMenu.cloud si trasferisce su SimPulse!<br /><br />
          SimPulse è la nuova piattaforma gratuita per creare e gestire i tuoi menu digitali. A breve, potrai trasferire i tuoi menu digitali su SimPulse automaticamente, con un click.<br />
          Se hai già stampato dei codici QR, non preoccuparti: i codici QR generati con EasyMenu.cloud continueranno a funzionare con SimPulse.
          <br /><br />
            Se hai bisogno di assistenza, siamo a tua disposizione all'indirizzo email info@simpulse.it.
        </div> -->
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <select class="nav-link active" @change="menuSelectChange">
            <option disabled selected value="_selectMenu">Selezione menu</option>
            <option disabled></option>
            <option :style="(!$route.params.short_url) ? 'font-weight:bold' : ''" value="">{{ (!$route.params.short_url) ? '>>>' : '' }} Visualizza tutti i menu</option>
            <option disabled></option>
            <option
              v-for="menu in getMenus"
              v-bind:key="'tab-menu-' + menu.menu_short_url"
              class="nav-link"
              :style="($route.params.short_url == menu.menu_short_url) ? 'font-weight:bold' : ''"
              :value="menu.menu_short_url"
            >
              {{ ($route.params.short_url == menu.menu_short_url) ? '>>>' : '' }} {{ menu.menu_name }}
            </option>
          </select>
        </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Menu',
  created() {
    this.fetchUserToken();
    this.fetchMenus();
  },
  computed: mapGetters(['getMenus', 'getCurrentMenu', 'getCurrentMenuMenu']),
  methods: {
    ...mapActions(['fetchUserToken', 'fetchMenus']),
    menuSelectChange: function(event) {
      this.$router.push('/menu/' + event.target.value)
      event.target.value = "_selectMenu"
    }
  },
}
</script>