<template>
  <mdb-row class="justify-content-center m-auto">
    <mdb-col style="max-width: 400px">
      <mdb-card>
        <mdb-card-body>
          <div class="alert alert-success" v-if="success">
            Ci siamo quasi! L'ultima cosa da fare è verificare l'indirizzo email
            inserito. Abbiamo inviato una email all'indirizzo {{ email }}:
            all'interno sono presenti le istruzioni per attivare l'account.
          </div>
          <form @submit="doRegistration" v-if="!success">
            <p align="center">
              <a
                class="btn btn-primary btn-link"
                href="https://admin.simpulse.it/"
                >Clicca qui per registrarti</a
              >
            </p>
            <div class="alert alert-info">
              EasyMenu.cloud è adesso su SimPulse!
              <br />
              <a href="https://admin.simpulse.it/"> Clicca qui</a> per creare il
              tuo account, o per accedere se hai già un account su SimPulse.
              <br /><br />
              <i
                >Nota: visualizzi questo messaggio perché non hai un account
                EasyMenu.cloud associato a <b>{{ form.email }}</b
                >. Se hai già un account EasyMenu.cloud, verifica di aver
                inserito l'indirizzo email corretto.
                <a href="/">Clicca qui</a> per tornare indietro.</i
              >
            </div>
          </form>
        </mdb-card-body>
      </mdb-card>
    </mdb-col>
  </mdb-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { mdbRow, mdbCol, mdbCard, mdbCardBody } from "mdbvue";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default {
  name: "AuthenticationRegistration",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
  },
  data() {
    return {
      loading: false,
      success: false,
      form: {
        email: "",
        password: "",
        password_check: "",
      },
    };
  },
  computed: mapGetters(["getAuthEmail"]),
  created() {
    this.form.email = this.getAuthEmail;
    this.$nextTick(() => {
      this.$refs.password.focus();
    });
  },
  methods: {
    ...mapActions(["setAuthEmail"]),
    changeEmail: function () {
      this.setAuthEmail(this.form.email);
    },
    doRegistration: function (event) {
      event.preventDefault();

      if (!validateEmail(this.form.email)) {
        alert("Please insert a valid email address");
      } else if (this.form.password !== this.form.password_check) {
        alert("Attenzione, le password non coincidono!");
      } else {
        this.$refs.recaptcha.execute();
      }
    },
    onCaptchaVerified: function (token) {
      this.loading = true;
      this.resetCaptcha();
      axios
        .post(`${this.$api_hostname}/api/v1/register`, {
          email: this.form.email,
          password: this.form.password,
          captcha_token: token,
        })
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.success = true;
          } else {
            alert("Error");
          }
        })
        .catch((err) => {
          this.loading = false;
          alert(
            "Errore. Si prega di controllare la connessione ad Internet e di provare ad aggiornare la pagina. Errore: " +
              err
          );
        });
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style scoped>
.profile-card-footer {
  background-color: #f7f7f7 !important;
  padding: 1.25rem;
}
.card.card-cascade .view {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
</style>
