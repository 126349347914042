<template>
  <mdb-modal size="lg" @close="setShowModalMenuQRCode(false)">
    <mdb-modal-header>
      <mdb-modal-title>Codice QR</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="text-center" v-if="qr_code_url != '' && qr_code_shareable_url != ''">
      <mdb-row>
        <mdb-col lg="6" md="12">
          Al momento non è possibile generare il codice QR. Per favore contattaci per maggiori informazioni.
        </mdb-col>
        <mdb-col lg="6" md="12">
          Consigliamo di scrivere, accanto al codice QR, anche il link alternativo che trovi sotto: in questo modo il cliente può raggiungere il menu digitale anche in caso di problemi riscontrati in fase di scansione.<hr />
          Link alternativo: <span class="font-weight-bold">{{ qr_code_shareable_url }}</span>
        </mdb-col>
      </mdb-row>
    </mdb-modal-body>
    <mdb-modal-footer>
      <mdb-btn color="none" @click.native="setShowModalMenuQRCode(false)">Chiudi</mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbRow, mdbCol, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue';
export default {
    name: "ModalMenuQRCode",
    props: ['menu_id'],
  components: {
    mdbRow, mdbCol,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
  },
  data() {
    return {
      qr_code_uri: "",
      qr_code_url: "",
      qr_code_shareable_url: ""
    }
  },
  created() {
    let menu = this.getMenu(this.menu_id);
    if (menu) {
      let short_url = menu.menu_short_url;
      this.qr_code_url = `https://menu.easymenu.cloud/${short_url}?ref=qr`;
      this.qr_code_shareable_url = `menu.easymenu.cloud/${short_url}`;
    } else {
      this.setShowModalMenuQRCode(false)
    }
  },
  computed: mapGetters(['getMenu']),
  methods: {
    ...mapActions(['setShowModalMenuQRCode']),
    generatedQRURI: function (uri) {
      this.qr_code_uri = uri;
    },
  }
};
</script>